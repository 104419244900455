export const thumbnailProgramEntry = {
  fields: [
    'date_time_start',
    'date_time_end'
  ],
  populate: {
    titles: true,
    slugs: true,
    tags: true,
    introductions: true,
    cover_image: true,
    date_time_ranges: true
  }
}
